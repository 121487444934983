<template>
  <div>
    <calendarTitle :data="data" :callback="function(d) { callback(d) }" :settings="{titlePlaceholder: this.$t('demo.privateTitlePlaceholder')}"/>
  </div>
</template>
<script>
export default {
  components: {
    calendarTitle () { return import('@/components/assistent/components/timeRange') }
  },
  props: {
    data: Object,
    callback: Function
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
